import React from "react";
import { Legal } from "../components/pages";

const PrivacyPage = () => (
  <Legal title="Privacy Policy">
    <p>Date of Last Revision: June 8th, 2022</p>
    <p>
      Welcome to the website (the “Site”) of Serenade Labs, Inc. (“Serenade,” “Serenade.ai,” “we,”
      “us,” or “our”). Serenade provides technology that enables end-users to write computer code by
      speaking (collectively, including the Site and any related desktop applications, the
      “Service”).
    </p>
    <p>
      This Privacy Policy explains what Personal Data (defined below) we collect, how we use and
      share that data, and your choices concerning our data practices. This Privacy Policy is
      incorporated into and forms part of our Terms of Service.
    </p>
    <p>
      Before using the Service or submitting any Personal Data to Serenade, please review this
      Privacy Policy carefully and contact us if you have any questions. By using the Service, you
      agree to the practices described in this Privacy Policy. If you do not agree to this Privacy
      Policy, please do not access the Site or otherwise use the Service.
    </p>
    <h2>Personal Data We Collect</h2>
    <p>
      We collect information that alone or in combination with other information in our possession
      could be used to identify you (“Personal Data”) as follows:
    </p>
    <p>
      <strong>Personal Data You Provide</strong>: We collect the following categories of Personal
      Data from you when you visit the site and use the Service.
    </p>
    <ul>
      <li>
        <strong>Identification Data</strong>: Serenade does not collect your name, email, or other
        contact information. When you install Serenade, a unique, random string is generated and
        stored with the application; if you opt into sharing data with Serenade, that data will be
        associated only with that string. In addition, we may have access to your Personal Data if
        such data is included in any audio or code files you have opted into sharing.
      </li>
      <li>
        <strong>Biometric Data</strong>: If you opt into sharing your audio data with Serenade, then
        we may collect your voice patterns.
      </li>
      <li>
        <strong>Communication Data</strong>: We may collect information when you contact us with
        questions or concerns and when you voluntarily respond to questionnaires, surveys or
        requests for market research seeking your opinion and feedback. Providing communication data
        is optional to you.
      </li>
      <li>
        <strong>Commercial Data</strong>: If you opt into sharing data with Serenade, we may retain
        a history of the products you browse and/or purchase using the Service (e.g., via Serenade
        browser extensions).
      </li>
      <li>
        <strong>Social Media Data</strong>: We have pages on social media sites like Instagram,
        Facebook, Twitter, and LinkedIn (“Social Media Pages”). When you interact with our Social
        Media Pages, we may collect Personal Data that you elect to provide to us, such as your
        contact details. In addition, the companies that host our Social Media Pages may provide us
        with aggregate information and analytics regarding the use of our Social Media Pages.
      </li>
    </ul>
    <p>
      <strong>Internet Activity Data</strong>: When you visit, use, and interact with the Service,
      we may receive certain information about your visit, use, or interactions. For example, we may
      monitor the number of people that visit the Service, peak hours of visits, which page(s) are
      visited, the domains our visitors come from (e.g., google.com, yahoo.com, etc.), and which
      browsers people use to access the Service (e.g., Chrome, Firefox, Microsoft Internet Explorer,
      etc.), broad geographical information, and navigation pattern. In particular, the following
      information is created and automatically logged in our systems:
    </p>
    <ul>
      <li>
        <strong>Log Data</strong>: Information that your browser automatically sends whenever you
        visit the Site. Log Data includes your Internet Protocol address, browser type and settings,
        the date and time of your request, and how you interacted with the Site.
      </li>
      <li>
        <strong>Cookies Data</strong>: Please see the “Cookies” section below to learn more about
        how we use cookies.
      </li>
      <li>
        <strong>Device Data</strong>: Includes name of the device, operating system, and browser you
        are using. Information collected may depend on the type of device you use and its settings.
      </li>
      <li>
        <strong>Usage Data</strong>: If you opt into sharing data with Serenade, we collect
        information about how you use our Service, such as the types of content that you view or
        engage with, the features you use, the actions you take, and the time, frequency, and
        duration of your activities.
      </li>
      <li>
        <strong>Location Data</strong>: We derive a rough estimate of your location from your IP
        address.
      </li>
      <li>
        <strong>Email Open/Click Data</strong>: We use pixels in our email campaigns that allow us
        to collect your email and IP address as well as the date and time you open an email or click
        on any links in the email.
      </li>
    </ul>
    <p>
      <strong>Cookies</strong>: We use cookies to operate and administer our Site, gather usage data
      on our Site, and improve your experience on it. A “cookie” is a piece of information sent to
      your browser by a website you visit. Cookies can be stored on your computer for different
      periods of time. Some cookies expire after a certain amount of time, or upon logging out
      (session cookies), others survive after your browser is closed until a defined expiration date
      set in the cookie (as determined by the third party placing it), and help recognize your
      computer when you open your browser and browse the Internet again (persistent cookies). For
      more details on cookies please visit{" "}
      <a href="http://www.allaboutcookies.org/">All About Cookies</a>.
    </p>
    <p>
      On most web browsers, you will find a “help” section on the toolbar. Please refer to this
      section for information on how to receive a notification when you are receiving a new cookie
      and how to turn cookies off. Please see the links below for guidance on how to modify your web
      browser’s settings on the most popular browsers:
    </p>
    <ul>
      <li>
        <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">
          Internet Explorer
        </a>
      </li>
      <li>
        <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?esab=a&s=cookies&r=6&as=s&redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">
          Mozilla Firefox
        </a>
      </li>
      <li>
        <a href="https://support.google.com/accounts/answer/61416?hl=en">Google Chrome</a>
      </li>
      <li>
        <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
          Apple Safari
        </a>
      </li>
    </ul>
    <p>
      Please note that if you limit the ability of websites to set cookies, you may be unable to
      access certain parts of the Site and you may not be able to benefit from the full
      functionality of the Site.
    </p>
    <p>
      Advertising networks may use cookies to collect Personal Data. Most advertising networks offer
      you a way to opt out of targeted advertising. If you would like to find out more information,
      please visit the Network Advertising Initiative’s online resources at
      http://www.networkadvertising.org and follow the opt-out instructions there.
    </p>
    <p>
      If you access the Site on your mobile device, you may not be able to control tracking
      technologies through the settings.
    </p>
    <p>
      <strong>Online Tracking and Do Not Track Signals</strong>: We and our third party service
      providers, including Facebook, may use cookies, pixels, or other tracking technologies to
      collect information about your browsing activities over time and across different websites
      following your use of the Site. Our Site currently does not respond to “Do Not Track” (“DNT”)
      signals and operates as described in this Privacy Policy whether or not a DNT signal is
      received. If we do respond to DNT signals in the future, we will update this Privacy Policy to
      describe how we do so.
    </p>
    <h2>How We Use Personal Data</h2>
    <p>We may use Personal Data for the following purposes:</p>
    <ul>
      <li>To provide the Service;</li>
      <li>To respond to your inquiries, comments, feedback, or questions;</li>
      <li>
        To send administrative information to you, for example, information regarding the Service
        and changes to our terms, conditions, and policies;
      </li>
      <li>To analyze how you interact with our Service;</li>
      <li>To maintain and improve the Service;</li>
      <li>To develop new products and services;</li>
      <li>
        To prevent fraud, criminal activity, or misuses of our Service, and to ensure the security
        of our IT systems, architecture, and networks; and
      </li>
      <li>
        To comply with legal obligations and legal process and to protect our rights, privacy,
        safety, or property, and/or that of our affiliates, you, or other third parties.
      </li>
    </ul>
    <p>
      <strong>Aggregated Information</strong>. We may aggregate Personal Data and use the aggregated
      information to analyze the effectiveness of our Service, to improve and add features to our
      Service, and for other similar purposes. In addition, from time to time, we may analyze the
      general behavior and characteristics of users of our Service and share aggregated information
      like general user statistics with prospective business partners. We may collect aggregated
      information through the Service, through cookies, and through other means described in this
      Privacy Policy.
    </p>
    <p>
      <strong>Marketing</strong>. We may use your Personal Data to contact you to tell you about
      products or services we believe may be of interest to you. For instance, if you elect to
      provide your email or telephone number, we may use that information to send you special
      offers. You may opt out of receiving emails by following the instructions contained in each
      promotional email we send you. You can also control the marketing emails and/or text messages
      you receive by updating your settings through your account. In addition, if at any time you do
      not wish to receive future marketing communications, you may contact us. If you unsubscribe
      from our marketing lists, you will no longer receive marketing communications but we will
      continue to contact you regarding management of your account, other administrative matters,
      and to respond to your requests.
    </p>
    <h2>Sharing and Disclosure of Personal Data</h2>
    <p>
      Serenade does not sell your Personal Data. In certain circumstances we may share the
      categories of Personal Data described above without further notice to you, unless required by
      the law, with the following categories of third parties:
    </p>
    <ul>
      <li>
        <strong>Vendors and Service Providers</strong>: To assist us in meeting business operations
        needs and to perform certain services and functions, we may share Personal Data with vendors
        and service providers.
      </li>
      <li>
        <strong>Business Transfers</strong>: If we are involved in a merger, acquisition, financing
        due diligence, reorganization, bankruptcy, receivership, sale of all or a portion of our
        assets, or transition of service to another provider (collectively a “Transaction”), your
        Personal Data and other information may be shared in the diligence process with
        counterparties and others assisting with the Transaction and transferred to a successor or
        affiliate as part of that Transaction along with other assets.
      </li>
      <li>
        <strong>Legal Requirements</strong>: If required to do so by law or in the good faith belief
        that such action is necessary to (i) comply with a legal obligation, including to meet
        national security or law enforcement requirements, (ii) protect and defend our rights or
        property, (iii) prevent fraud, (iv) act in urgent circumstances to protect the personal
        safety of users of the Service, or the public, or (v) protect against legal liability.
      </li>
      <li>
        <strong>Affiliates</strong>: We may share Personal Data with our affiliates, meaning an
        entity that controls, is controlled by, or is under common control with Serenade. Our
        affiliates may use the Personal Data we share in a manner consistent with this Privacy
        Policy.
      </li>
    </ul>
    <h2>Data Retention</h2>
    <p>
      We keep Personal Data for as long as reasonably necessary for the purposes described in this
      Privacy Policy, while we have a business need to do so, or as required by law (e.g. for tax,
      legal, accounting, or other purposes), whichever is longer.
    </p>
    <h2>Update Your Information</h2>
    <p>Please contact us if you need to change or correct your Personal Data.</p>
    <h2>Children</h2>
    <p>
      Our Service is not directed to children who are under the age of 16. Serenade.ai does not
      knowingly collect Personal Data from children under the age of 16. If you have reason to
      believe that a child under the age of 16 has provided Personal Data to Serenade.ai through the
      Service please contact us and we will endeavor to delete that information from our databases.
    </p>
    <h2>No Health Information</h2>
    <p>
      Our Service is not intended to be used with for purposes for which compliance with the Health
      Insurance Portability and Accountability Act of 1996 (“HIPAA”) is required. You expressly
      agree not to share any “Protected Health Information” that is subject to HIPAA with us through
      the Service
    </p>
    <h2>Links to Other Websites</h2>
    <p>
      The Service may contain links to other websites not operated or controlled by Serenade.ai,
      including social media services (“Third Party Sites”). The information that you share with
      Third Party Sites will be governed by the specific privacy policies and terms of service of
      the Third Party Sites and not by this Privacy Policy. By providing these links we do not imply
      that we endorse or have reviewed these sites. Please contact the Third Party Sites directly
      for information on their privacy practices and policies.
    </p>
    <h2>Security</h2>
    <p>
      You use the Service at your own risk. We implement commercially reasonable technical,
      administrative, and organizational measures to protect Personal Data both online and offline
      from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However,
      no Internet or e-mail transmission is ever fully secure or error free. In particular, e-mail
      sent to or from us may not be secure. Therefore, you should take special care in deciding what
      information you send to us via the Service or e-mail. Please keep this in mind when disclosing
      any Personal Data to Serenade.ai via the Internet. In addition, we are not responsible for
      circumvention of any privacy settings or security measures contained on the Service, or third
      party websites.
    </p>
    <h2>International Users</h2>
    <p>
      By using our Service, you understand and acknowledge that your Personal Data will be
      transferred from your location to our facilities and servers in the United States.
    </p>
    <h2>Your Choices</h2>
    <p>
      In certain circumstances providing Personal Data is optional. However, if you choose not to
      provide Personal Data that is needed to use some features of our Service, you may be unable to
      use those features. You can also contact us to ask us to update or correct your Personal Data.
      You may also delete your account. Please note that we will need to verify that you have the
      authority to delete the account and certain activity generated prior to deletion may remain
      stored by us and may be shared with third parties as detailed in this Privacy Policy.
    </p>
    <h2>Changes to the Privacy Policy</h2>
    <p>
      The Service and our business may change from time to time. As a result we may change this
      Privacy Policy at any time. When we do we will post an updated version on this page, unless
      another type of notice is required by the applicable law. By continuing to use our Service or
      providing us with Personal Data after we have posted an updated Privacy Policy, or notified
      you by other means if applicable, you consent to the revised Privacy Policy and practices
      described in it.
    </p>
    <h2>Contact us</h2>
    <p>
      If you have any questions about our Privacy Policy or information practices, please feel free
      to contact us at our designated request address: contact@serenade.ai
    </p>
    <br />
  </Legal>
);

export default PrivacyPage;
